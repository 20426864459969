import SendbirdStyle from './sendbird';
import ZendeskStyle from './zendesk';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
   body: {
      '--chat-width': '420px',
      '--chat-height': '700px',
      '--chat-offset': '15px',
      '--app-height': '100dvh',
      '.async-hyde': {
         opacity: '0 !important',
      },
   },
   ...ZendeskStyle,
   ...SendbirdStyle,
};
