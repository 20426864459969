import { ComponentStyleConfig } from '@chakra-ui/react';

const FormLabel: ComponentStyleConfig = {
   baseStyle: {
      color: 'white',
      fontWeight: 600,
      fontSize: 'xs',
      lineHeight: 1.4,
      marginBottom: '0.5rem',
   },
};

export default FormLabel;
