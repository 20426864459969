import { ComponentStyleConfig } from '@chakra-ui/react';
import type { SystemStyleFunction } from '@chakra-ui/theme-tools';

const variantToggle: SystemStyleFunction = (props) => {
   const { colorScheme } = props;
   return {
      minW: '100%',
      display: 'flex',
      color: colorScheme === 'neutral' ? 'text.primary' : 'text-white',
      minH: 10,
      py: 4,
      px: 4,
      bg:
         colorScheme === 'neutral'
            ? 'background.secondary'
            : 'background.primary',
      borderRadius: '10px',
   };
};

const variantSolid: SystemStyleFunction = (props) => {
   const { colorScheme } = props;

   const bg = `${colorScheme}.500`;
   let textColor = 'white';

   if (colorScheme === 'buttonTertiary') {
      textColor = 'text.primary';
   }

   const hoverButton = {
      background: `${colorScheme}.600`,
   };

   const activeButton = {
      background: `${colorScheme}.700`,
      boxShadow: 'none',
   };

   const focusVisibleButton = {
      boxShadow: '0 0 0 4px rgba(18, 23, 35, 0.25)',
   };

   const disabledButton = {
      opacity: 0.25,
      boxShadow: 'none',
   };

   return {
      bg,
      color: textColor,
      stroke: textColor,
      borderRadius: '30px',
      boxShadow:
         '0px 16px 30px rgba(0, 0, 0, 0.08), 0px 6px 10px rgba(0, 0, 0, 0.04), 0px 3px 5px rgba(0, 0, 0, 0.03)',
      _focusVisible: focusVisibleButton,
      _disabled: disabledButton,
      _hover: hoverButton,
      _active: activeButton,
   };
};

const variantOutline: SystemStyleFunction = (props) => {
   const { colorScheme } = props;

   let textColor = 'text.primary';
   let _hoverTextColor = 'white';
   let _hoverBgColor = 'text.primary';
   let _hoverBorderColor = 'text.primary';

   if (colorScheme === 'white') {
      textColor = 'white';
      _hoverTextColor = 'text.primary';
      _hoverBgColor = 'white';
      _hoverBorderColor = 'white';
   }

   return {
      borderRadius: '30px',
      textColor,

      _hover: {
         textColor: _hoverTextColor,
         bg: _hoverBgColor,
         borderColor: _hoverBorderColor,
      },
   };
};

const variantIcon: SystemStyleFunction = (props) => {
   return {
      background: 'transparent',
      minH: '40px',
      padding: 0,
      maxW: '40px',
   };
};

const variantLink: SystemStyleFunction = (props) => {
   return {
      fontSize: {
         base: 'xs',
         lg: 'md',
      },
      width: 'auto',
      minHeight: 'none',
      textDecoration: 'underline',
      textUnderlineOffset: 4,
      color: 'gray.700',
      fontWeight: 400,
      display: 'inline',
   };
};

const solidLeft: SystemStyleFunction = (props) => {
   return {
      width: '100%',
      minHeight: 'none',
      textDecoration: 'none',
      fontWeight: 600,
   };
};

const variants = {
   solid: variantSolid,
   outline: variantOutline,
   link: variantLink,
   icon: variantIcon,
   solidLeft: solidLeft,
   toggle: variantToggle,
};

const sizeLarge = () => {
   return {
      textTransform: 'uppercase',
      letterSpacing: '.1em',
      lineHeight: '1',
      fontFamily: 'body',
      width: {
         base: '100%',
         lg: 'auto',
      },
      minHeight: '56px',
      fontSize: 'lg',
      px: 12,
   };
};

const sizeMedium: SystemStyleFunction = () => {
   return {
      lineHeight: '6',
      fontSize: 'lg',
      width: {
         base: '100%',
         lg: 'auto',
      },
      minHeight: '56px',
      px: 12,
   };
};

const sizeSmall: SystemStyleFunction = ({ colorScheme, variant }) => {
   return {
      lineHeight: '1.25rem',
      fontSize: 'xs',
      width: {
         base: '100%',
         lg: 'auto',
      },
      minHeight: '48px',
      px: { base: variant === 'link' ? 0 : 6, lg: variant === 'link' ? 0 : 12 },
   };
};

const sizeExtraSmall: SystemStyleFunction = ({ variant }) => {
   return {
      lineHeight: '1.25rem',
      fontWeight: 600,
      fontSize: 'xs',

      width: 'auto',
      minHeight: '42px',
      px: {
         base: variant === 'link' ? 0 : 6,
         lg: variant === 'link' ? 0 : 12,
      },
   };
};

const sizes = {
   lg: sizeLarge,
   md: sizeMedium,
   sm: sizeSmall,
   xs: sizeExtraSmall,
};

const Button: ComponentStyleConfig = {
   variants,
   sizes,

   defaultProps: {
      size: 'md',
      colorScheme: 'buttonPrimary',
      variant: 'solid',
   },
};

export default Button;
