import { ComponentStyleConfig, LayoutProps } from '@chakra-ui/react';

const sizeSmall: LayoutProps = {
   w: '120px',
   h: '120px',
};

const sizes = {
   test: sizeSmall,
};

const Avatar: ComponentStyleConfig = {
   sizes,
   defaultProps: {
      width: '3500px',
   },
};

export default Avatar;
