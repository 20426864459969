'use client';

import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser';

import { getClientPublicRuntimeConfig } from '@/services/env/utils/client-public-runtime-config';

const siteId = getClientPublicRuntimeConfig().customerIoSiteId;
const trackingApiKey = getClientPublicRuntimeConfig().customerIoTrackingApiKey;

// CustomerIO write key format: siteId:trackingApiKey,
// dont ask why and how long it took to figure this out
const writeKey = `${siteId}:${trackingApiKey}`;

export const cioAnalytics = AnalyticsBrowser.load(
   {
      writeKey,
   },
   {
      integrations: {
         'Customer.io In-App Plugin': {
            siteId,
         },
      },
   },
);

export default function CustomerIoAnalytics() {
   return null;
}
