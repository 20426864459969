import { ComponentStyleConfig } from '@chakra-ui/react';

const Link: ComponentStyleConfig = {
   baseStyle: {
      textDecoration: 'underline',
      textUnderlineOffset: '4px',
   },
};

export default Link;
