import { SystemStyleObject } from '@chakra-ui/react';
import { colors } from '../foundations';

const SendbirdStyle: SystemStyleObject = {
   '#__next .sendbird-channel-list': {
      backgroundColor: 'transparent',
      width: '100%',
   },

   'body.is-sendbird-opened': {
      '#main-nav': {
         zIndex: 98,
      },
   },

   '#sendbird-dropdown-portal .menu-item-reply': {
      display: 'none',
   },

   '#sendbird-wrapper': {
      '@media (min-width: 992px)': {
         maxHeight: '95vh',
      },

      '.sendbird-message-item-reaction-menu': {
         display: 'none',
      },

      '.sendbird-conversation': {
         backgroundColor: 'white',
         border: 'none',

         '.sendbird-message-content.outgoing:hover': {
            '.sendbird-message-content__middle__body-container__created-at': {
               display: 'flex !important',
            },
            '.sendbird-message-content__left': {
               display: 'none !important',
            },
         },

         '&__footer': {
            backgroundColor: colors.chat.background.tertiary,
            '&__typing-indicator': {
               display: 'none',
            },

            '.sendbird-message-input-wrapper': {
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               paddingTop: {
                  base: 4,
                  lg: 5,
               },
            },
            '.sendbird-message-input--textarea': {
               border: 'none',
               borderRadius: '40px',
               backgroundColor: 'white',
               transition: 'background 0.2s ease-in-out',
               overflowY: 'auto !important',
               fontSize: {
                  base: '16px!',
                  lg: '14px!',
               },
               minH: {
                  base: '59px',
                  lg: 'auto',
               },

               _focus: {
                  backgroundColor: '#FFF8F3',
                  border: 'none',
                  outline: 'none',
                  boxShadow: 'none',
               },
            },
            '.sendbird-message-input': {
               pl: '0',
            },
            '.sendbird-message-input--placeholder': {
               left: '15px',
            },
            '.sendbird-message-input--attach ': {
               display: 'none',
            },
            '.sendbird-message-input--send': {
               bottom: '12px',
               path: {
                  fill: 'black !important',
               },
            },
         },
      },
      '.sendbird-text-message-item-body': {
         '&.incoming': {
            backgroundColor: colors.chat.background.secondary,
         },
         '&.outgoing': {
            color: '#ffffff',
            backgroundColor: colors.chat.background.tertiary,

            '&:hover': {
               backgroundColor: colors.chat.background.tertiary,
            },
         },

         '&.mouse-hover': {
            '&.outgoing': {
               backgroundColor: colors.chat.background.tertiary,
            },
         },
      },
      '.sendbird-chat-header': {
         backgroundColor: colors.chat.background.tertiary,
         paddingTop: '10px',
         height: '72px',
         paddingBottom: '10px',
      },
      '.sendbird-conversation__messages': {
         overflow: 'hidden',
      },
      '.sendbird-conversation__messages-padding': {
         overflow: 'hidden',
         overflowY: 'auto',
      },
      '.sendbird-conversation__typing-indicator': {
         display: 'none',
      },
      '.sendbird-conversation__footer': {
         height: {
            base: '90px',
            lg: 24,
         },

         backgroundColor: colors.chat.background.tertiary,
         marginTop: '0px',
      },
   },
};

export default SendbirdStyle;
