interface IColorScheme {
   50: string;
   100: string;
   200: string;
   300: string;
   400: string;
   500: string;
   600: string;
   700: string;
   800: string;
   900: string;
}
const generateColorScheme = (
   primaryColor: string,
   partialScheme: Partial<IColorScheme>,
): IColorScheme => ({
   50: partialScheme[50] || primaryColor,
   100: partialScheme[100] || primaryColor,
   200: partialScheme[200] || primaryColor,
   300: partialScheme[300] || primaryColor,
   400: partialScheme[400] || primaryColor,
   500: primaryColor,
   600: partialScheme[600] || primaryColor,
   700: partialScheme[700] || primaryColor,
   800: partialScheme[800] || primaryColor,
   900: partialScheme[900] || primaryColor,
});

const text = {
   primary: '#121723',
   secondary: '#444444',
   tertiary: 'rgba(18, 23, 35, 0.6)',
   fourth: '#ff9a49',
};

const background = {
   primary: '#417777',
   secondary: '#E8F3F3',
   tertiary: '#203C3C',
   fourth: '#1F545E',
   fifth: '#EFE7DB',
};

const accent = {
   orange: '#FF9C4B',
   orangeDark: '#b6723c',
   orangeFaded: '#FFCEA7',
   green: '#9DE3E3',
   peach: '#FFD9BB',
   darkGreen: '#19434B',
   peachFaded: '#FFF3E9',
   sand: '#F7F2ED',
};

const chat = {
   background: {
      primary: accent.sand,
      secondary: '#FFFFFF',
      tertiary: 'rgb(31, 84, 94)',
      quaternary: accent.orange,
   },
   text: {
      primary: text.primary,
      secondary: accent.sand,
   },
};

const buttonPrimary = generateColorScheme('rgb(18, 23, 35)', {
   600: 'rgba(18, 23, 35, .75)', // hover
   700: 'rgba(18, 23, 35, .50)', // pressed
});

const buttonSecondary = generateColorScheme('#1F545E', {
   600: '#19434B', // hover
   700: '#163B42', // pressed
});

const buttonTertiary = generateColorScheme('white', {
   600: '#F5F5F5', // hover
   700: '#E7E7E7', // pressed
});

const buttonGhost = generateColorScheme('transparent', {
   600: '#1F545E', //hover
   700: '#1F545E', //pressed
});

const neutral = {
   100: '#E7E7E7',
   200: '#F5F5F5',
   300: '#D1D1D1',
   400: '#D8D8D8',
   500: '#777777',
   600: '#555555',
   700: '#444444',
   800: '#333333',
};

const card = {
   primary: 'white',
   secondary: '#1F545E',
   tertiary: '#E8F3F3',
   inactive: '#7AA0A0',
};

const line = {
   primary: '#EDEDED',
   secondary: '#CDD5D5',
   faded: 'rgba(65, 119, 119, 0.1)',
};

const element = {
   primary: '#121723',
   secondary: '#417777',
   tertiary: '#CDD5D5',
};

const form = {
   default: '#E8F3F3',
   hover: 'white',
   active: 'white',
   focus: ' rgba(18, 23, 35, 0.25)',
};

const alert = {
   primary: '#FF9C4B',
   secondary: '#FFEBDB',
   tertiary: '#FF5C3D',
   quanternary: '#E8F3F3',
   error: '#FFDED8',
};

const colors = {
   background,
   neutral,
   buttonPrimary,
   buttonSecondary,
   buttonTertiary,
   buttonGhost,
   accent,
   text,
   card,
   line,
   element,
   form,
   alert,
   chat,
};

export default colors;
