import { ComponentStyleConfig, SystemStyleFunction } from '@chakra-ui/react';

const variantPill: SystemStyleFunction = (props) => {
   const { colorScheme = 'primary' } = props;

   return {
      tab: {
         opacity: 0.75,
         paddingLeft: '0 !important',
         paddingRight: '0 !important',
         fontWeight: 500,
         flex: 1,
         height: '30px',
         borderRadius: '8px',
         textColor: 'text.primary',
         transition: 'all .3s ease',

         _selected: {
            p: 0,
            opacity: 1,
            borderBottom: 'none',
            bg: colorScheme === 'primary' ? 'background.primary' : 'white',
            textColor: colorScheme === 'primary' ? 'white' : 'text.primary',

            _hover: {
               opacity: 1,

               cursor: 'default',
            },
         },

         _hover: {
            bg: colorScheme === 'primary' ? 'background.primary' : 'white',
            opacity: 0.4,
            textColor: colorScheme === 'primary' ? 'white' : 'text.primary',
         },
      },
      tablist: {
         padding: '4px',
         gap: 1,
         borderBottom: 'none',
         borderRadius: '10px',
      },
   };
};

const variantSolidRounded = {
   tab: {
      opacity: 0.75,
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
      fontWeight: 500,
      fontSize: 'xs',
      bg: 'card.secondary',
      color: 'white',
      py: '22px',
      flex: 1,
      height: '30px',
      borderRadius: 0,
      transition: 'all .3s ease',

      _selected: {
         opacity: 1,
         py: '22px',
         borderBottom: 'none',
         textColor: 'background.primary',
         bg: 'white',

         _hover: {
            opacity: 1,

            cursor: 'default',
         },
      },

      _hover: {
         background: 'white',
         opacity: 0.6,
         textColor: 'background.primary',
      },
   },
   tablist: {
      padding: '0',
      gap: '1px',
      borderBottom: 'none',
      borderRadius: '12px',
      overflow: 'hidden',
   },
};

const variantButton = {
   tablist: {
      background: 'transparent',
      border: 'none',
      gap: 2,
      maxW: '100%',
      overflowX: 'auto',
   },
   tab: {
      opacity: 1,
      background: 'white',
      color: 'element.secondary',
      border: '1px solid',
      borderColor: 'element.secondary',
      fontWeight: 500,
      height: '2.875rem',
      transition: 'all .3s ease',
      borderRadius: 'md',
      whiteSpace: 'nowrap',
      pl: '4!',
      pr: '4!',

      _selected: {
         border: '1px solid',
         borderColor: 'element.secondary',
         textColor: 'white',
         bg: 'element.secondary',

         _hover: {
            opacity: 1,
            bg: 'element.secondary',
            color: 'white',
         },
      },

      _hover: {
         background: 'line.faded',
         textColor: 'element.secondary',
      },
   },
};

const variants = {
   pill: variantPill,
   'solid-rounded': variantSolidRounded,
   button: variantButton,
};

const Tabs: ComponentStyleConfig = {
   variants,
   baseStyle: {
      tab: {
         opacity: 0.75,
         paddingLeft: '0 !important',
         paddingRight: '0 !important',
         fontWeight: 500,

         _selected: {
            p: 0,
            opacity: 1,
            borderBottom: '3px solid white',
         },
      },
      tablist: {
         display: 'flex',
         gap: { base: 6, lg: 8 },
         pb: '1px',
         borderBottomWidth: '1px !important',
         borderBottomColor: 'card.inactive !important',
      },
      tabpanel: {
         px: 0,
      },
   },
   defaultProps: {
      colorScheme: '#CDD5D5',
   },
};

export default Tabs;
