import { ComponentStyleConfig } from '@chakra-ui/react';

const InputGroup: ComponentStyleConfig = {
   baseStyle: {
      addon: {
         bg: 'red.500',
      },
   },
};

export default InputGroup;
